<template lang="pug">
tiptap-editor(
  ref="tiptapEditor",
  :value="value"
  :options="options"
  :allMentionUsers="allMentionUsers"
  @input="emitValue",
  @mentionReplaced="mentionReplaced")
</template>

<script>
  import { mapState } from 'vuex'
  import TiptapEditor from './TiptapEditor.vue'

  export default {
    props: {
      options: { type: Object, default: () => ({}) },
      value: { type: String, default: null },
      onlyExternalUsers: { type: Boolean, default: null },
      users: { type: Array, default: () => null },
    },

    computed: {
      ...mapState({
        allMentionUsers: function mentionUsers(state) {
          const stateUserKey = this.onlyExternalUsers
            ? 'users'
            : 'teamAndInternalUsers'
          return (this.users || state.team[stateUserKey]).map((u) => {
            return { id: u.email || u.username_email, value: u.name }
          })
        },
      }),
    },

    methods: {
      emitValue(v) {
        this.$emit('input', v)
      },

      mentionReplaced(v) {
        this.$emit('mentionReplaced', v)
      },
    },

    components: {
      TiptapEditor,
    },
  }
</script>
