<template lang="pug">
div
  component.d-flex.align-items-center.flex-wrap(:is="isBubbleMenu ? 'bubble-menu' : 'div'" :editor="editor")
    template(v-for="(item, index) in getItemsToDisplay()")
      div(class="divider" v-if="item.type === 'divider'" :key="index")
      menu-item(v-else :key="index" v-bind="item", :faIcon="item.faIcon" :isActive="item.isActive", style="position: relative;")
      chrome-picker(
      v-if="displayColorPicker",
      :id="`color-picker-input-${_uid}`",
      style="position: absolute; bottom: 150px; right: 100px; z-index: 100;"
      :value="colors",
      @input="updateFromColorPicker"
    )
</template>

<script>
  import { BubbleMenu } from '@tiptap/vue-2'
  import MenuItem from './MenuItem.vue'
  import { Chrome } from 'vue-color'

  export default {
    components: {
      BubbleMenu,
      MenuItem,
      'chrome-picker': Chrome,
    },

    props: {
      isBubbleMenu: {
        type: Boolean,
        default: false,
      },
      isDefaultEditor: {
        type: Boolean,
        default: false,
      },
      editor: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        displayColorPicker: false,
        colorPickerType: '',
        colors: {
          hex: '#1c567d',
        },
        items: [
          {
            icon: 'paragraph',
            faIcon: 'fas fa-paragraph',
            title: 'Paragraph',
            action: () => this.editor.chain().focus().setParagraph().run(),
            isActive: () => this.editor.isActive('paragraph'),
          },
          {
            icon: 'h-1',
            faIcon: 'fas fa-h1',
            title: 'Heading 1',
            action: () =>
              this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
            isActive: () => this.editor.isActive('heading', { level: 1 }),
          },
          {
            icon: 'h-2',
            faIcon: 'fas fa-h2',
            title: 'Heading 2',
            action: () =>
              this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
            isActive: () => this.editor.isActive('heading', { level: 2 }),
          },
          {
            type: 'divider',
          },
          {
            icon: 'bold',
            faIcon: 'fas fa-bold',
            title: 'Bold',
            action: () => this.editor.chain().focus().toggleBold().run(),
            isActive: () => this.editor.isActive('bold'),
          },
          {
            icon: 'italic',
            faIcon: 'fas fa-italic',
            title: 'Italic',
            action: () => this.editor.chain().focus().toggleItalic().run(),
            isActive: () => this.editor.isActive('italic'),
          },
          {
            icon: 'underline',
            faIcon: 'fas fa-underline',
            title: 'Underline',
            action: () => this.editor.chain().focus().toggleUnderline().run(),
            isActive: () => this.editor.isActive('underline'),
          },
          {
            icon: 'strikethrough',
            faIcon: 'fas fa-strikethrough',
            title: 'Strike',
            action: () => this.editor.chain().focus().toggleStrike().run(),
            isActive: () => this.editor.isActive('strike'),
          },
          {
            icon: 'links-line',
            faIcon: 'fas fa-link',
            title: 'Set-Link',
            action: () => {
              {
                const currentHref = this.editor.getAttributes('link').href
                  ? this.editor.getAttributes('link').href
                  : ''
                const url = window.prompt('URL', currentHref)
                if (url == null) return
                if (url == '') return this.editor.commands.unsetLink()
                return this.editor
                  .chain()
                  .focus()
                  .extendMarkRange('link')
                  .setLink({ href: this.getValidUrl(url), target: '_blank' })
                  .run()
              }
            },
            isActive: () => this.editor.isActive('link'),
          },
          {
            type: 'divider',
          },
          {
            icon: 'list-unordered',
            faIcon: 'fas fa-list-ul',
            title: 'Bullet List',
            action: () => this.editor.chain().focus().toggleBulletList().run(),
            isActive: () => this.editor.isActive('bulletList'),
          },
          {
            icon: 'list-ordered',
            faIcon: 'fas fa-list-ol',
            title: 'Ordered List',
            action: () => this.editor.chain().focus().toggleOrderedList().run(),
            isActive: () => this.editor.isActive('orderedList'),
          },
          {
            icon: 'code-view',
            faIcon: 'fas fa-code',
            title: 'Code',
            action: () => this.editor.chain().focus().toggleCode().run(),
            isActive: () => this.editor.isActive('code'),
          },
          {
            icon: 'code-box-line',
            faIcon: 'fas fa-code',
            title: 'Code Block',
            action: () => this.editor.chain().focus().toggleCodeBlock().run(),
            isActive: () => this.editor.isActive('codeBlock'),
          },
          {
            type: 'divider',
          },
          {
            icon: 'align-left',
            faIcon: 'fas fa-align-left',
            title: 'Left Justify',
            action: () =>
              this.editor.chain().focus().setTextAlign('left').run(),
            isActive: () => this.editor.isActive({ textAlign: 'left' }),
          },
          {
            icon: 'align-center',
            faIcon: 'fas fa-align-center',
            title: 'Justify Center',
            action: () =>
              this.editor.chain().focus().setTextAlign('center').run(),
            isActive: () => this.editor.isActive({ textAlign: 'center' }),
          },
          {
            icon: 'align-right',
            faIcon: 'fas fa-align-right',
            title: 'Right Justify',
            action: () =>
              this.editor.chain().focus().setTextAlign('right').run(),
            isActive: () => this.editor.isActive({ textAlign: 'right' }),
          },
          {
            type: 'divider',
          },
          {
            icon: 'double-quotes-l',
            faIcon: 'fas fa-quote-left',
            title: 'Blockquote',
            action: () => this.editor.chain().focus().toggleBlockquote().run(),
            isActive: () => this.editor.isActive('blockquote'),
          },
          {
            icon: 'separator',
            title: 'Page Break',
            action: () => this.editor.chain().focus().setHorizontalRule().run(),
          },
          {
            type: 'divider',
          },
          {
            icon: 'text-wrap',
            faIcon: 'fas fa-text-width',
            title: 'Hard Break',
            action: () => this.editor.chain().focus().setHardBreak().run(),
          },
          {
            icon: 'format-clear',
            faIcon: 'fas fa-eraser',
            title: 'Clear Format',
            action: () =>
              this.editor.chain().focus().clearNodes().unsetAllMarks().run(),
          },
          {
            type: 'divider',
          },
          {
            icon: 'table-line',
            faIcon: 'fas fa-table',
            title: 'Insert Table',
            action: () =>
              this.editor
                .chain()
                .focus()
                .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                .run(),
          },
          {
            icon: 'paint-fill',
            faIcon: 'fas fa-fill-drip',
            title: 'Toggle Header Cell',
            action: () => this.editor.chain().focus().toggleHeaderCell().run(),
          },
          {
            icon: 'insert-column-right',
            faIcon: 'fas fa-columns',
            title: 'Insert Column Before',
            action: () => this.editor.chain().focus().addColumnBefore().run(),
          },
          {
            icon: 'insert-column-left',
            faIcon: 'fas fa-columns',
            title: 'Insert Column After',
            action: () => this.editor.chain().focus().addColumnAfter().run(),
          },
          {
            icon: 'insert-row-bottom',
            faIcon: 'fas fa-table',
            title: 'Insert Row Before',
            action: () => this.editor.chain().focus().addRowBefore().run(),
          },
          {
            icon: 'insert-row-top',
            faIcon: 'fas fa-table',
            title: 'Insert Row After',
            action: () => this.editor.chain().focus().addRowAfter().run(),
          },
          {
            icon: 'delete-row',
            faIcon: 'fas fa-trash-alt',
            title: 'Delete Row',
            action: () => this.editor.chain().focus().deleteRow().run(),
          },
          {
            icon: 'delete-column',
            faIcon: 'fas fa-trash-alt',
            title: 'Delete Column',
            action: () => this.editor.chain().focus().deleteColumn().run(),
          },
          {
            icon: 'close-circle-line',
            faIcon: 'fas fa-trash-alt',
            title: 'Delete Table',
            action: () => this.editor.chain().focus().deleteTable().run(),
          },
          {
            type: 'divider',
          },
          {
            icon: 'arrow-go-back-line',
            faIcon: 'fas fa-undo',
            title: 'Undo',
            action: () => this.editor.chain().focus().undo().run(),
          },
          {
            icon: 'arrow-go-forward-line',
            faIcon: 'fas fa-redo',
            title: 'Redo',
            action: () => this.editor.chain().focus().redo().run(),
          },
          // change font color
          {
            icon: 'color-fill',
            faIcon: 'fas fa-paint-brush',
            title: 'Color',
            action: () => {
              this.togglePicker('font')
            },
          },
          // change background color
          {
            icon: 'color-fill',
            faIcon: 'fas fa-fill-drip',
            title: 'Highlight',
            action: () => {
              this.togglePicker('background')
            },
            isActive: () => this.editor.isActive('highlight'),
          },
        ],
      }
    },
    methods: {
      getValidUrl(url = '') {
        let newUrl = window.decodeURIComponent(url)
        newUrl = newUrl.trim().replace(/\s/g, '')

        if (/^(:\/\/)/.test(newUrl)) {
          return `http${newUrl}`
        }
        if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
          return `http://${newUrl}`
        }

        return newUrl
      },

      updateFromColorPicker(color) {
        this.colors = color
        if (color.rgba.a == 1) {
          this.colorValue = color.hex
        } else {
          this.colorValue = `rgba(${color.rgba.r}, ${color.rgba.g}, ${color.rgba.b}, ${color.rgba.a})`
        }

        if (!this.colorValue) return

        if (this.colorPickerType === 'font') {
          this.editor.chain().focus().setColor(this.colorValue).run()
        }
        if (this.colorPickerType === 'background') {
          this.editor
            .chain()
            .focus()
            .setHighlight({ color: this.colorValue })
            .run()
        }
        this.colorPickerType = ''
      },

      togglePicker(type) {
        this.colorPickerType = type
        this.displayColorPicker = !this.displayColorPicker
      },

      documentClick(e) {
        if (!this.$el.contains(e.target)) {
          this.displayColorPicker = false
        }
      },

      getItemsToDisplay() {
        if (!this.isDefaultEditor) {
          return this.items
        } else {
          const titlesToInclude = [
            'Heading 1',
            'Heading 2',
            'Paragraph',
            'Bold',
            'Italic',
            'Underline',
            'Strike',
            'Highlight',
            'Bullet List',
            'Ordered List',
            'Left Justify',
            'Justify Center',
            'Right Justify',
            'Clear Format',
            'Set-Link',
            'Color',
            'Background Color',
          ]

          return this.items.filter((item) => {
            return titlesToInclude.includes(item.title)
          })
        }
      },
    },

    mounted() {
      document.addEventListener('click', this.documentClick)
    },

    beforeDestroy() {
      document.removeEventListener('click', this.documentClick)
    },
  }
</script>

<style lang="scss" scoped>
  .divider {
    width: 2px;
    height: 1.25rem;
    background-color: black;
    margin-left: 0.5rem;
    margin-right: 0.75rem;
  }

  .vc-chrome {
    box-shadow: none;
    border: 1px solid #c3c3c3;
    border-radius: 0.25rem;
    padding: 0.5rem;
  }
</style>
