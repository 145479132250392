<template lang="pug">
  div.comment-card.card-body.rounded.p-2(:class="commentCardClass")
    div(v-if="header")
      strong {{ header }}
    div
      div.d-flex.align-items-center
        div.d-flex.align-items-center
          user-avatar.mr-1(
            :id="`comment-card-${_uid}`",
            height="25px",
            max-height="25",
            :should-show-close="false",
            :user-id="comment.user_id")
          h6.m-0 {{ $store.state.getUserName(comment.user_id) }}
          div.text-info.ml-1(v-if="comment.internal_only")
            strong (internal)
        div.ml-auto.text-gray.small.d-flex.align-items-start
          div {{ getFormattedDate(comment.created_at, 'MMM D, YYYY h:mm a') }}
          div.d-flex.align-items-start.ml-1(v-if="comment.user_id == userId")
            a(@click="isEditing = !isEditing") ({{ (isEditing) ? 'cancel' : 'edit' }})
            a.ml-1.text-danger(@click="deleteComment")
              i.fal.fa-times-circle
      div.d-flex.mt-2(v-if="!isEditing")
        //- div.mr-4 Comment:
        div.w-100(v-html="comment.comment")
      div.mt-2(v-else)
        div.form-group.mb-2(:class="userId != comment.user_id && 'bg-white'")
          rich-text-editor(
            v-model="comment.comment"
            :users="mentionUsers"
            @mentionReplaced="addMention")
          div.custom-control.custom-checkbox.mt-2(v-if="isInternalUser && hasInternalFlag && !onlyInternal")
            input.custom-control-input(
              :id="`comment-card-${comment.id}`"
              type="checkbox"
              v-model="comment.internal_only")
            label.nowrap.custom-control-label(:for="`comment-card-${comment.id}`")
              | Comment is internal only?
        div.form-group.m-0.text-center
          button.btn.btn-sm(
            type="button"
            :class="(userId == comment.user_id && 'btn-white text-primary') || 'btn-primary'"
            @click="updateComment()") Update Comment
</template>

<script>
  import { mapState } from 'vuex'
  import TimeHelpers from '../../factories/TimeHelpers'

  export default {
    props: {
      comment: { type: Object, required: true },
      header: { type: String, default: null },
      mentionUsers: { type: Array, default: null },
      onlyInternal: { type: Boolean, default: false },
    },

    data() {
      return {
        isEditing: false,
        mentions: [],
      }
    },

    computed: {
      ...mapState({
        isInternalUser: (_, getters) => getters.isInternalUser,
        userId: (state) => state.auth.user.id,
      }),

      hasInternalFlag() {
        return typeof this.comment.internal_only !== 'undefined'
      },

      commentCardClass() {
        return this.comment.internal_only
          ? 'internal'
          : this.userId == this.comment.user_id
          ? 'self'
          : 'bg-light align-self-end'
      },
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,

      addMention(item) {
        this.mentions.push({ key: item.value, email: item.id })
      },

      deleteComment() {
        this.$emit('delete', this.comment)
        this.isEditing = false
      },

      updateComment() {
        this.mentions = this.mentions.filter((u) =>
          (this.comment.comment || '').includes(`data-id="${u.email}"`)
        )
        this.$emit('update', this.comment, this.mentions)
        this.isEditing = false
      },
    },
  }
</script>

<style lang="scss">
  span.mention {
    background-color: #d3e1eb;
    color: #1c567d !important;
    border-radius: 6px;
    padding: 3px 2px;
  }
</style>
