<template lang="pug">
button(
  class="menu-item"
  :class="{ 'is-active': isActive ? isActive() : null }"
  @click="action"
  :title="title"
)
  i.font-color-editor(v-if="faIcon" :class="faIcon")
  svg(v-else, class="remix")
    use(:xlink:href="require('remixicon/fonts/remixicon.symbol.svg') + `#ri-${icon}`")
</template>

<script>
  import 'remixicon/fonts/remixicon.css'

  export default {
    props: {
      icon: {
        type: String,
        required: true,
      },

      faIcon: {
        type: String,
        default: null,
      },

      title: {
        type: String,
        required: true,
      },

      action: {
        type: Function,
        required: true,
      },

      isActive: {
        type: Function,
        default: null,
      },

      isDefaultEditor: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        mutableIsActive: null,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .menu-item {
    width: 1.75rem;
    height: 1.75rem;
    color: #0d0d0d;
    border: none;
    background-color: transparent;
    border-radius: 0.4rem;
    padding: 0.25rem;
    margin-right: 0.25rem;

    svg {
      width: 100%;
      height: 100%;
      fill: currentColor;
    }
    &.remix {
      &.is-active,
      &:hover {
        color: #fff;
        background-color: #0d0d0d;
      }
    }
  }

  .menu-item {
    .font-color-editor {
      color: #818ea3 !important;
    }
    &.is-active {
      .font-color-editor {
        color: #06c !important;
      }
    }
    &.hover {
      .font-color-editor {
        color: #06c !important;
      }
    }
  }
</style>
